import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "field-labels"
    }}>{`Field Labels`}</h1>
    <p>{`A field label is text that appears next to or above a field. It should give users information about
what the field contains. Keep field label text concise but still descriptive.`}</p>
    <h2 {...{
      "id": "capitalization"
    }}>{`Capitalization`}</h2>
    <p>{`Field labels may be capitalized in 2 ways, depending on their length and placement in the UI:`}</p>
    <ul>
      <li parentName="ul">{`Use title case for short labels that are to the left or above a check box.`}</li>
      <li parentName="ul">{`Use title case for labels that identify an object.`}</li>
      <li parentName="ul">{`Use sentence case for longer labels that are placed to the right of a check box, especially if the
label reads like a phrase.`}</li>
    </ul>
    <p>{`We encourage the use of shorter, more concise labels whenever possible.`}</p>
    <h2 {...{
      "id": "punctuation"
    }}>{`Punctuation`}</h2>
    <ul>
      <li parentName="ul">{`If the label is phrased as a question, use a question mark at the end.`}</li>
    </ul>
    <h2 {...{
      "id": "prepositions"
    }}>{`Prepositions`}</h2>
    <ul>
      <li parentName="ul">{`Avoid using a preposition at the end of a field label.`}</li>
      <li parentName="ul">{`If you do use a preposition at the end of a field label, capitalize it.`}</li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Created On`}</li>
      </ul>
    </Do>
    <p>{`See also: `}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/radio-buttons"
      }}>{`Radio Buttons`}</a>{`,
`}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/dropdown-menus"
      }}>{`Drop-down Menus`}</a>{`, and
`}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/instructional-text"
      }}>{`Instructional Text`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      